.category-selector {
  margin: 20px auto;
  max-width: 600px;
  padding: 15px;
}

.category-selector h3 {
  margin-bottom: 15px;
  text-align: center;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.category-item {
  background: var(--card-bg, #fff);
  border: 1px solid var(--border-color, #ddd);
  border-radius: 8px;
  padding: 10px;
  transition: all 0.2s ease;
}

.category-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.category-label {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
}

.category-label input[type="checkbox"] {
  margin-top: 4px;
}

.category-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.category-name {
  font-weight: bold;
  font-size: 1.1em;
}

.category-description {
  color: var(--text-secondary, #666);
  font-size: 0.9em;
}

.word-count {
  color: var(--text-secondary, #666);
  font-size: 0.8em;
}

/* Dark mode support */
.dark-mode .category-item {
  --card-bg: #444;
  --border-color: #555;
  --text-secondary: #aaa;
}

.dark-mode .category-item:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
} 