/* Reset body margins and apply default background color */
body {
  margin: 0;
  padding: 0;
  background-color: #ffffff; /* Default light mode background color */
  color: #000000; /* Default light mode text color */
  height: 100%;
}

/* Ensure the App component takes the full height of the viewport */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  font-family: Arial, sans-serif;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flashcard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  background-color: rgba(255, 0, 0, 0.1); /* Light red */
  position: relative;
}

.success-gif {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
}

.flashcard {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 80vw;
  max-width: 500px;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
  flex-direction: column;
}

.content {
  font-size: 1.33em; /* Reduced by roughly 33% from 2em */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.english {
  font-size: 1.3em;
  margin-bottom: 10px;
}

.russian {
  font-size: 1.8em;
  font-weight: bold;
  margin: 5px 0;
}

.phonetic {
  font-style: italic;
  color: #666;
  margin: 5px 0;
  font-size: 0.9em;
}

.flag-icon {
  width: 30px;
  height: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.detected-speech {
  background-color: rgba(0, 255, 0, 0.1); /* Light green */
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 14px;
  /*font-style: italic;*/
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adds space between buttons */
  margin-top: 20px;
  background-color: transparent; /* Ensure no background color */
}

.nav-button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #45a049;
}

.nav-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.nav-button.active {
  background-color: #f44336;
}

.nav-button.active:hover {
  background-color: #da190b;
}

/* Dark mode styles */
.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode body {
  background-color: #121212; /* Apply dark mode background to body */
  color: #e0e0e0; /* Apply dark mode text color */
}

.dark-mode .flashcard {
  background-color: #333;
  border-color: #555;
}

.dark-mode .nav-button,
.dark-mode .toggle-button {
  background-color: #1e88e5;
}

.dark-mode .nav-button:hover,
.dark-mode .toggle-button:hover {
  background-color: #1565c0;
}

.dark-mode .twitter-link {
  color: #90caf9;
}

.dark-mode .twitter-link:hover {
  color: #bbdefb;
}

/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
  margin-top: 40px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Mode icon styles */
.mode-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.speech-status {
  margin-top: 10px;
  font-style: italic;
  color: #666;
}

.speech-status p {
  margin: 0;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #f0f0f0;
  display: inline-block;
}

.language-selector {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
}

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
}

.language-links {
  display: flex;
  gap: 10px;
}

.language-link.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
}

.language-link.button:hover {
  background-color: #0056b3;
}

.footer {
  margin-top: 20px;
  text-align: center;
}

.footer-logo {
  width: 50px;
  height: auto;
}

.language-link {
  display: block;
  margin: 10px 0;
}